import { render, staticRenderFns } from "./HorizontalDefinitions.vue?vue&type=template&id=68201bd7&scoped=true&"
import script from "./HorizontalDefinitions.vue?vue&type=script&setup=true&lang=js&"
export * from "./HorizontalDefinitions.vue?vue&type=script&setup=true&lang=js&"
import style0 from "./HorizontalDefinitions.vue?vue&type=style&index=0&id=68201bd7&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "68201bd7",
  null
  
)

export default component.exports