<template>
    <div>
        <h1 class="fs-500 fs-14 text-primary-one mt-7">Horizontal Parameter</h1>
        <select @change="handleProperty(hSelectedProperty, 'property_h_id')" v-model="hSelectedProperty" class="fs-14 fw-400 text-secondary-one bg-transparent border border-secondary-five block w-full px-4 py-2 rounded mt-4">
            <option :value="[]" selected>Select Property Set</option>
            <option v-for="item in parameters" :key="item.id" :value="item">{{ item.title }}</option>
        </select>
        <select @change="handlePropertyCollection(hSelectedCollection, 'property_h_p_id')" v-model="hSelectedCollection" class="fs-14 fw-400 text-secondary-one bg-transparent border border-secondary-five block w-full px-4 py-2 rounded mt-7">
            <option :value="[]" selected>Select Property Param</option>
            <option v-for="item in getPropertyCollections(hSelectedProperty)" :key="item.id" :value="item">{{ item.title }}</option>
        </select>
        <!-- ----------- -->
        <h1 class="fs-500 fs-14 text-primary-one mt-7">Vertical Parameter</h1>
        <select @change="handleProperty(vSelectedProperty, 'property_v_id')" v-model="vSelectedProperty" class="fs-14 fw-400 text-secondary-one bg-transparent border border-secondary-five block w-full px-4 py-2 rounded mt-4">
            <option :value="[]" selected>Select Property Set</option>
            <option v-for="item in parameters" :key="item.id" :value="item">{{ item.title }}</option>
        </select>
        <select @change="handlePropertyCollection(vSelectedCollection, 'property_h_v_id')" v-model="vSelectedCollection" class="fs-14 fw-400 text-secondary-one bg-transparent border border-secondary-five block w-full px-4 py-2 rounded mt-7">
            <option :value="[]" selected>Select Property Param</option>
            <option v-for="item in getPropertyCollections(vSelectedProperty)" :key="item.id" :value="item">{{ item.title }}</option>
        </select>
    </div>
</template>


<script setup>
    import { ref, watchEffect } from 'vue'
    import { isEmpty } from 'lodash'
    import useHighLevelConcept from '@/views/screens/roadmap/strategicRoadmap/roadmapConcept/useHighLevelConcept.js'
    
    const { strategy, updateStrategy, callback, parameters } = useHighLevelConcept()

    const hSelectedProperty = ref([])
    const hSelectedCollection = ref([])
    
    const vSelectedProperty = ref([])
    const vSelectedCollection = ref([])


    const handleProperty = (property, key) => {
        if(key == 'property_h_id' && strategy.value?.parameters){
            strategy.value.parameters = [{
                id: strategy.value.parameters[0]?.id,
                property_id: property.id
            }]
        }
        if(key == 'property_v_id'){
            strategy.value.secondary_parameters = [{
                id: !isEmpty(strategy.value.secondary_parameters) ? strategy.value.secondary_parameters[0]?.id : null,
                property_id: property.id
            }]
        }
        updateStrategy(strategy.value)
    }

    const handlePropertyCollection = (collection, key) => {
        if(key == "property_h_p_id" && strategy.value.parameters){
            strategy.value.parameters = [{
                id: collection.id,
                property_id: strategy.value.parameters[0]?.property_id
            }]
        }
        if(key == "property_h_v_id"){
            strategy.value.secondary_parameters = [{
                id: collection.id,
                property_id: !isEmpty(strategy.value.secondary_parameters) ? strategy.value.secondary_parameters[0]?.property_id : null
            }]
        }
        updateStrategy(strategy.value)
    }

    
    const setConfigOfProperty = (parameters) => 
    {
        if(isEmpty(parameters)) return
        
        let property, collection;
        // ---horizontal
        if(strategy.value.parameters){
            let property_h_id   = strategy.value.parameters ? strategy.value.parameters[0].property_id : null
            let property_h_p_id = strategy.value.parameters ? strategy.value.parameters[0].id : null
            
            property = parameters.find(item => item.id == property_h_id)
            
            if(property) {
                hSelectedProperty.value = property
            }
            if(hSelectedProperty.value.property_collections){
                collection = hSelectedProperty.value.property_collections.find(item => item.id == property_h_p_id)
                if(collection) hSelectedCollection.value = collection
            }
        }
        
        // --- vertical
        if(strategy.value.secondary_parameters){
            let property_v_id = strategy.value.secondary_parameters ? strategy.value.secondary_parameters[0].property_id : null
            let property_h_v_id = strategy.value.secondary_parameters ? strategy.value.secondary_parameters[0].id : null
            
            property = parameters.find(item => item.id == property_v_id)
            if(property) {
                vSelectedProperty.value = property
            }

            if(vSelectedProperty.value.property_collections){
                collection = vSelectedProperty.value.property_collections.find(item => item.id == property_h_v_id)
                if(collection) vSelectedCollection.value = collection
            }
        }        
    }

    const getPropertyCollections = (property) => {
        if(property?.property_collections){
            return property?.property_collections
        }
        return []
    }

    let isUpdated = false
    watchEffect(async () => {
        if(!parameters.value.length || isUpdated) return
        setConfigOfProperty(parameters.value)
        callback.value()
        isUpdated = true
    })
</script>