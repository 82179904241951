import { render, staticRenderFns } from "./VerticalDefinitions.vue?vue&type=template&id=6e185e16&scoped=true&"
import script from "./VerticalDefinitions.vue?vue&type=script&setup=true&lang=js&"
export * from "./VerticalDefinitions.vue?vue&type=script&setup=true&lang=js&"
import style0 from "./VerticalDefinitions.vue?vue&type=style&index=0&id=6e185e16&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6e185e16",
  null
  
)

export default component.exports