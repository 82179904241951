<template>
    <div>
        <h1 class="fw-500 fs-14 text-primary-one flex justify-between items-center mt-7">
            Vertical Definitions
            <button @click="addDefinition" class="">
                <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g opacity="0.6">
                        <path d="M8.57023 15.2562C4.88833 15.2562 1.90356 12.2714 1.90356 8.58952C1.90356 4.90762 4.88833 1.92285 8.57023 1.92285C12.2521 1.92285 15.2369 4.90762 15.2369 8.58952C15.2329 12.2697 12.2505 15.2521 8.57023 15.2562ZM3.2369 8.70419C3.26844 11.6383 5.6643 13.9959 8.59859 13.9802C11.5329 13.9643 13.9033 11.5812 13.9033 8.64685C13.9033 5.71252 11.5329 3.32937 8.59859 3.31352C5.6643 3.29782 3.26844 5.65536 3.2369 8.58952V8.70419ZM9.2369 11.9229H7.90356V9.25619H5.2369V7.92285H7.90356V5.25619H9.2369V7.92285H11.9036V9.25619H9.2369V11.9229Z" fill="#3D3D3D"/>
                    </g>
                </svg>                    
            </button>
        </h1>
        <div 
            class="relative group"
            v-for="(verticalDefinition, index) in getDefinitions" 
            :key="`hdef-${index}`"
        >
            <input
                type="text" 
                :value="verticalDefinition"
                class="fs-14 fw-400 text-secondary-one bg-transparent border border-secondary-five block w-full px-4 py-2 rounded mt-4"
                @input="(e) => changeDefinition(e, index, getDefinitions)"
            >
            <svg @click="removeDefinition(index)" class="transform rotate-45 absolute top-3 cursor-pointer right-2 opacity-0" width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g opacity="0.6">
                    <path d="M8.57023 15.2562C4.88833 15.2562 1.90356 12.2714 1.90356 8.58952C1.90356 4.90762 4.88833 1.92285 8.57023 1.92285C12.2521 1.92285 15.2369 4.90762 15.2369 8.58952C15.2329 12.2697 12.2505 15.2521 8.57023 15.2562ZM3.2369 8.70419C3.26844 11.6383 5.6643 13.9959 8.59859 13.9802C11.5329 13.9643 13.9033 11.5812 13.9033 8.64685C13.9033 5.71252 11.5329 3.32937 8.59859 3.31352C5.6643 3.29782 3.26844 5.65536 3.2369 8.58952V8.70419ZM9.2369 11.9229H7.90356V9.25619H5.2369V7.92285H7.90356V5.25619H9.2369V7.92285H11.9036V9.25619H9.2369V11.9229Z" fill="#3D3D3D"/>
                </g>
            </svg> 
        </div>
    </div>
</template>

<script setup>
    import { computed, onMounted } from 'vue'
    import useHighLevelConcept from '@/views/screens/roadmap/strategicRoadmap/roadmapConcept/useHighLevelConcept.js'
    
    const { strategy, updateStrategy } = useHighLevelConcept()

    const changeDefinition = (e, index, definitions) => {
        let data = e.target.value
        definitions.splice(index, 1, data)
        updateStrategy(strategy.value)
    }

    const removeDefinition = (index) => {
        getDefinitions.value.splice(index, 1)
        updateStrategy(strategy.value)
    }

    const addDefinition = () => {
        if(strategy.value.definitions && strategy.value.definitions.length){
            strategy.value.definitions[1].push('New Definition')
            updateStrategy(strategy.value)
        }
    }

    const getDefinitions = computed(() => {
        if(strategy.value.definitions && strategy.value.definitions.length){
            return strategy.value.definitions[1]
        }

        let defaultDefinitions = [['New Definition','New Definition']]
        strategy.value.definitions = defaultDefinitions
        updateStrategy(strategy.value)
        return defaultDefinitions
    })

    onMounted(() => {
        if(strategy.value.definitions.length < 2){
            strategy.value.definitions.push(['New Definition', 'New Definition'])
            updateStrategy(strategy.value)
        }
    })
</script>

<style scoped>
    .group:hover svg{
        opacity: 1;
    }
</style>